const getPreservedQueryString = (
  currentSearchParams: URLSearchParams,
  newParam?: { key: string; value: string },
) => {
  const acceptedParams = [
    'email',
    'src',
    'platform',
    'checkout',
    'referrerUID',
    'eventId',
    'gclid',
    'reconnect',
    'onboarding',
    'irclickid',
    'sharedid',
    'irpid',
    'irgwc',
    'provider_id',
    'provider_nickname',
  ];

  const newParams = new URLSearchParams();
  currentSearchParams.forEach((value, key) => {
    if (acceptedParams.includes(key) && value) {
      newParams.append(key, value);
    }
  });

  if (newParam) newParams.append(newParam.key, newParam.value);

  return newParams.toString() ? `?${newParams.toString()}` : '?';
};

export default getPreservedQueryString;
